import React, {useState, useEffect, useContext} from 'react';
import { ptBR } from '@material-ui/core/locale';
import { createTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import AssignmentIcon from '@material-ui/icons/Assignment';
import IconButton from '@material-ui/core/IconButton'
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import conexao from '../../../../Services/api';
import Alert from '@material-ui/lab/Alert';
import CircularLoad from '../../../../Components/Load';
import { Button } from '@material-ui/core'
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { StateGlobal } from '../../../../ContextAPI/context'
import { SportsPhysiotherapyContext } from '../../../../ContextAPI/SportsPhysiotherapyContext'
import '../css/ListagemP.css';

const useStyles = makeStyles((theme) => ({
    form: {
       width:'100%',
        border: 'none',
        marginBottom: '2rem'
    },
    search: {
        background: '#f1f1f1',
        boxShadow: '0px 1px 10px rgba(25, 32, 56, 0.08)',
        borderRadius: '3px',
        height:'45px',
        fontSize:'15px',
        border: 'none',
        padding: '15px',
        minWidth: '350px',
        
     },
     iconSearch:{
         color: '#8F8F8F'
     },
     divScroll:{
      overflow: 'auto',
      height: '48vh'
     }
  }));


const theme = createTheme({
  palette: {
    secondary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#229fb5',
      contrastText: '#fff',
    },
    primary: {
      light: '#ffffff',
      main: '#ffffff',
      dark: '#ffffff',
      contrastText: '#8F8F8F',
    }
  },
}, ptBR);


export default function CardModelosProntos(props) {
    const classes = useStyles();
    const [modelosProntos, setModelosProntos] = useState('');
    const [typeAlert, setTypeAlert] = useState();
    const [message, setMessage] = useState();
    const [load, setLoad] = useState(false);
    const [nameModelo, setNameModelo] = useState('');
    const { setLoadSportsPhysiotherapyComponent } = useContext(SportsPhysiotherapyContext)
    const [search, setSearch] = useState('');
    const [formID, setFormID] = useState('');
    const History = useHistory();

    const { 
      pos, 
      setPos, 
      MontagemProntuario, 
      setMontagemProntuario, 
      setAnswer, 
      Answer, 
      respEdit, 
      validaProntuario, 
      setvalidaProntuario, 
      NameProntuario, 
      setNameProntuario, 
      setIdProntuario, 
      validation, 
      setValidation,
      setFormPatientID
    } = StateGlobal();

    const { 
      setChartComponentsIndex,
      sportsPhysiotherapyChartAnswers,
      sportsPhysiotherapyView 
    } = useContext(SportsPhysiotherapyContext)
  
    
  async function listaModeloPronto() {
      setLoad(true)
      await conexao.post(`/form/default/list`,{
        "search": nameModelo,
      })
        .then((response) => {
          setLoad(false)
          setModelosProntos(response.data.data);
          if(response.data.data.length<1){
            setModelosProntos('')
            setTypeAlert('error');
            setMessage('Prontuário não encontrado, verifique o nome do modelo buscado.');
            setTimeout(() => {
              //setTypeAlert('') => Alterado por Elton. Propriedade inválida;
              setMessage('');
            }, 3000);
          }
        }).catch((error) => {
            setLoad(false)
            setModelosProntos('')
            setTypeAlert('error');
            setMessage('Prontuário não encontrado, verifique o nome do modelo buscado.');
            setTimeout(() => {
              //setTypeAlert('') => Alterado por Elton. Propriedade inválida;
              setMessage('');
            }, 3000);
        })
  }

  useEffect(() => {
    listaModeloPronto()
    console.log(props)
},[]);

useEffect(() => {
  if(nameModelo===''){
    setModelosProntos([])
    listaModeloPronto()
  }

},[nameModelo]);


function start(e) {  // INICIAR ATENDIMENTO GERA ARRAY SEM RESPOSTA
  conexao.get('/form/' + e.id).then((res) => {
    setIdProntuario(e.id)
    setFormPatientID(res.data.data.id)
    
    const id = Math.floor(Math.random() * 256)

    const arredit = []

    localStorage.getItem('selectedUser')
    // localStorage.getItem('patientID')
    console.log('localStorage patientID', localStorage.getItem('patientID'))
    console.log('FormPatientID patientID',  res.data.data.id)

    const iniciaAtendimento = {
      form_id: res.data.data.id, 
      user_id: localStorage.getItem('selectedUser'), 
      patient_id: props.patientID
    }

    res.data.data.steps.map((item, index) => {

      const idsessao = Math.floor(Math.random() * 256)



      const components = []
      item.questions.map((item) => {


        // const types = ['textocurto', 'textolongo', 'data', 'numerico', 'telefone', 'email','label' ]//teste
        const types = ['textocurto', 'textolongo', 'data', 'numerico', 'telefone', 'email', 'label', 'image']//teste
        //componentes basicos de texto
        types.map((itemtypes) => {
          if (item.type === itemtypes) {
            components.push({ type: item.type, component: item.type, tempId: item.id, title: item.description, required: item.role, idsessao: idsessao })
          }
        })

        //tipo de componente especial diferente com selects
        const typesepeciais = ['escaladedor', 'musculoesqueletico', 'visceral', 'cranianovistainferior', 'cranianovistaanterior', 'cranianovistaposterior', 'cranianovistalateraldireita']//teste
        typesepeciais.map((itemtypes) => {
          //console.log('especiasselects', item)
          if (item.type === itemtypes) {//pego os options do selelect aqui thiagao manda pra gente
            components.push({ type: item.type, component: item.type, tempId: item.id, title: item.description, required: item.role, idsessao: idsessao, options: item.options })
          }
        })


        //ODONTOLOGICO
        const typesodontologico = ['odontologico']
        typesodontologico.map((itemtypes) => {
          if (item.type === itemtypes) {//pego os options do selelect aqui thiagao manda pra gente
            components.push({ type: item.type, component: item.type, tempId: item.id, title: item.description, required: item.role, idsessao: idsessao, options: item.options })
          }
        })

        //DESENVOLVIMENTO MOTOR
        const typesdesenvolvimentomotor = ['desenvolvimentomotor']
        typesdesenvolvimentomotor.map((itemtypes) => {
          if (item.type === itemtypes) {
            //console.log('desenvolvimentomotor', item)
            components.push({ type: item.type, component: item.type, tempId: item.id, title: item.description, required: item.role, idsessao: idsessao, options: item.options })
          }
        })



        const typesosteopatia = ['osteopatiapediatrica']
        typesosteopatia.map((itemtypes) => {
          if (item.type === itemtypes) {
            //  console.log('osteopatiapediatrica', item)
            components.push({ type: item.type, component: item.type, tempId: item.id, title: item.description, required: item.role, idsessao: idsessao, options: item.options })
          }
        })

        const typescurvadecrescimento = ['curvadecrescimento']
          typescurvadecrescimento.map((itemtypes) => {
            if (item.type === itemtypes) {
              //  console.log('osteopatiapediatrica', item)
              components.push({ type: item.type, component: item.type, tempId: item.id, title: item.description, required: item.role, idsessao: idsessao, options: item.options })
            }
          })



        //componente selecao
        const typesselecao = ['selecao']
        typesselecao.map((itemtypes)=>{
          if (item.type === 'selecao') {
            const arrop = []
            if (item.options) {
              item.options.map((item) => {
                arrop.push({ text: item.text, value: item.id })
              })
            }
            components.push({ type: "selecao", component: item.type, tempId: item.id, title: item.description, required: item.role, idsessao: idsessao, opcoes: arrop })

          }
        })
        
        const typescheck = ['check']
        typesselecao.map((itemtypes)=>{
          if (item.type === 'check') {
            const arrop = []
            if (item.options) {
              item.options.map((item) => {
                arrop.push({ text: item.text, value: item.id })
              })
            }
            components.push({ type: "check", component: item.type, tempId: item.id, title: item.description, required: item.role, idsessao: idsessao, opcoes: arrop })

          }
        })
       

      })

      //gera sessoes
      arredit.push({ componentsessao: item.title, id: idsessao, montagem: components, titulosessao: item.description })

    })

    conexao.post('/form/patient/create', iniciaAtendimento).then((res) => {
      // localStorage.setItem('answeredFormId', res.data.data.id)
      props.selectForm(res.data.data.id)
      setFormID(res.data.data.id)
    }).catch((error) => {

    })

    criaAnswer(arredit)
    createValidate(arredit)


    setMontagemProntuario(arredit)
    setNameProntuario(res.data.data.title);


   setPos(2)


  }).catch((error) => {
  })

}


  function createValidate(e) {//CRIAR ARRAY PARAR VALIDACAO DOS COMPONENTES DINAMICOS
  
    var step = []
  
    e.map((item, index)=>{
    
      var comp = []
      item.montagem.map((mont)=>{
  
        var arr = []
            
        if(mont.required == 1){
        
          if(mont.type === 'check'){

            arr = {id:mont.tempId, type: mont.type, name: mont.title, error: false, options: mont.opcoes}
          

          }else{
            arr = {id:mont.tempId, type: mont.type, name: mont.title, error: false}
            
          }

          comp.push(arr)
        }
      
      })
          
            
      var data = {id: item.id, name: item.titulosessao, components: comp}

      step.push(data)

      console.log('step', step)

      setValidation(step)

    })



    // var valida = {}
    // e.map((item) => {
    //   item.montagem.map((obj, index) => {
    //     valida = { ...valida, ... { [obj.tempId]: { question_id: obj.tempId, value: '', required: obj.required === '1' ? true : false, sessao: obj.idsessao, error: false, message: '* Reposta Obrigatória' } } }


    //     //console.log('OBJETO ===>', obj)
    //   })
    // })


    // setvalidaProntuario(valida)

  }


  function criaAnswer(arredit) {//MUITO IMPORTANTE PEGA VALORES DO BACK E CONVERTE PARA PRENCHER OS COMPONENETES DINAMICOS E ARMAZENAR SUAS UPDADTE NO ARRAY ANSWER DO CONTEXT API

    //MEUS MODELOS CRIAÇÃO DE RESPOSTAS
    const answers = []
    arredit.map((item) => {


      item.montagem.map((create) => {

        // console.log('CREATE',create)
        if (create.type === 'check') {
          
          create.opcoes.map((opcao)=>{
            answers.push({
              question_id: create.tempId,
              question_type: create.type,
              id_check: opcao.value,
              answer: create.answer !== undefined ? create.answer : ''
            })
          })
          
        }


        if (create.type === 'osteopatiapediatrica') {

          answers.push({
            question_id: create.tempId,
            question_type: create.type,
            answer: create.answer !== undefined ? create.answer : [],
            form_step_question_option_id: ''

          })

        }

        if (create.type === 'curvadecrescimento') {

          answers.push({
            question_id: create.tempId,
            question_type: create.type,
            answer: create.answer !== undefined ? create.answer : [],
            form_step_question_option_id: ''

          })

        }


        if (create.type === 'desenvolvimentomotor') {

          answers.push({
            question_id: create.tempId,
            question_type: create.type,
            answer: create.answer !== undefined ? create.answer : [],
            form_step_question_option_id: ''

          })

        }


        if (create.type === 'odontologico') {

          answers.push({
            question_id: create.tempId,
            question_type: create.type,
            answer: create.answer !== undefined ? create.answer : [],
            form_step_question_option_id: ''

          })

        }

        if (create.type === 'cranianovistainferior' || create.type === 'musculoesqueletico' || create.type === 'visceral' || create.type === 'cranianovistaanterior' || create.type === 'cranianovistaposterior' || create.type === 'cranianovistalateraldireita') {
          answers.push({
            question_id: create.tempId,
            question_type: create.type,
            answer: create.answer !== undefined ? create.answer : [],
            form_step_question_option_id: ''

          })
        }
        console.log('ans', create)
        if (create.type === 'textocurto' || create.type === 'textolongo' || create.type === 'data' || create.type === 'numerico' || create.type === 'telefone' || create.type === 'email' || create.type === 'label'  || create.type === 'selecao' || create.type === 'image') {
          
          answers.push({
            question_id: create.tempId,
            question_type: create.type,
            answer: create.answer !== undefined ? create.answer : ''
          })

        }


      })
    })

    const arr = answers.slice()
  
    setAnswer(arr)
  }

  function iniciarFisioterapiaEsportiva() {
    const payload = {
      patient_id: props.patientID,
      data: sportsPhysiotherapyChartAnswers,
      title: 'Fisioterapia Esportiva'
    }

    conexao.post('/form/patient/createinfo', payload).then((res) => {
      localStorage.setItem('answeredFormId', res.data.data.id)
      console.log("SCMP")
      console.log(res.data.data.id)
      setFormID(res.data.data.id)
      setLoadSportsPhysiotherapyComponent(true)
    }).catch((error) => {

    })
  }

  const busca = (event) => {
    if (event.key === 'Enter') {
      setLoad(true)
      listaModeloPronto();
      setModelosProntos([])
    } if (event._reactName === 'onClick') {
      setLoad(true)
      setModelosProntos([])
      listaModeloPronto();
    }
    return true
  }

  return (
    <div>
      <form className={classes.root} noValidate autoComplete="off">
        <FormControl className={clsx(classes.form)}>
          <InputBase
              id="outlined-adornment-weight"              
              endAdornment={<InputAdornment position="end">{<IconButton onClick={(event) => busca(event)}><SearchIcon className={classes.iconSearch}  /></IconButton>}</InputAdornment>}
              //labelWidth={0}
              disabled={load ? true : false}
              onKeyDown={busca}
              onChange={(e) => setNameModelo(e.target.value)}
              placeholder="Busque por um modelo"
              className={clsx(classes.search)}
          />
        </FormControl>
      </form>

      <div className={classes.divScroll}>
        {load && <div className="div-load-lista-modelos"><CircularLoad/></div> }

        <div className="CardModelos-envolve">
          <div className="CardModelos-envolve-text-icon">
            <AssignmentIcon className="CardModelos-icon-pront"/>
            <p>FISIOTERAPIA ESPORTIVA</p>
          </div>

          <div>
            <Button 
              className={clsx(classes.btnInicarAtendimento,"color-primary")}
              variant="contained"
              color="primary"
              // onClick={() => { window.location.href = '/fisioterapia-esportiva' }}
              onClick={() => { iniciarFisioterapiaEsportiva() }}
            >
              <PlayArrowIcon /> Iniciar
            </Button>
          </div>
        </div>
        
        {modelosProntos ?
          modelosProntos.map((modeloPronto) => (
            <div className="CardModelos-envolve">
              <div className="CardModelos-envolve-text-icon">
                <AssignmentIcon className="CardModelos-icon-pront"/>
                <p>{modeloPronto.title}</p>
              </div>
              <div>
                <Button
                  className={clsx(classes.btnInicarAtendimento,"color-primary")}
                  variant="contained"
                  color="primary"
                  onClick={() => { start(modeloPronto) }}
                >
                  <PlayArrowIcon /> Iniciar
                </Button>
              </div>
            </div>
          )):
          <div>
            {message ?
              <div className="Modal-agendamento-alert">
                <Alert variant="filled" severity={typeAlert}>
                  {message}
                </Alert>
              </div>
            :''}
          </div>
        }
      </div>
    </div>
  );
}