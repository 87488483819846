import React, {useEffect, useState} from 'react';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Logo from '../../Assets/Images/LogoCP.svg';
import Btngoogle from '../../Components/Google';
import FormLogin from './Form';
import './css/login.css';
import ModalEsqueciSenha from './ResetResenha';


const theme = createTheme({
  palette: {
    primary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#229fb5',
      contrastText: '#fff',
    }
  },
});

export default function Login() {
  const [openEsqueci, setOpenEsqueci] = useState(false);

  const closeModalEsqueci = () => {
    setOpenEsqueci(false);
  }

  useEffect( () => {
    localStorage.removeItem('promocao-black-friday')
  })
  
  return (
       <ThemeProvider theme={theme}>
        <div className="login-background-image">
            <div className="login-card-form-login">
              <img src={Logo} className="Login-logo"/>
                  <FormLogin/>
                  <div>
                     <Button 
                        href="#text-buttons" 
                        onClick={()=>setOpenEsqueci(true)}
                        className="lost-password" 
                        color="default">
                        Esqueci minha senha
                      </Button>
                  </div>
                  <div className="trial">
                    {/* <p>Teste GRATUITAMENTE por 7 dias</p> */}
                    <div class="Login-div-row">
                      <Button 
                        href="/cadastro" 
                        className="signup-button" >
                        Consultório
                      </Button>

                      <Button 
                        href="/cadastro-clinica" 
                        className="signup-button">
                        Clínica
                      </Button>
                    </div>
                  </div>
            </div>
            <ModalEsqueciSenha open={openEsqueci} handleModal={closeModalEsqueci}/>
        </div>
      </ThemeProvider>
  );
}