import pdfMake from 'pdfmake/build/pdfmake'
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment'


const date = new Date()
const newDate = moment(date).format('DD/MM/YYYY')

const  NameReport = localStorage.getItem("title_form");
const SelectedPatient = localStorage.getItem("SelectedPatient")

function getTextNomeclatura(nomeclatura){
  let textNomeclatura = {
    'Dr': "Dr.",
    'Dra': "Dra.",
    '-': ''
  }
  return textNomeclatura[nomeclatura] ?? ''
}


function PdfByDesenvolvimentoMotor( DataGuia , listaImgBase64 , idPaciente ){

    const url = DataGuia.avatar
    let newUrl = '' 
    const toDataURL = (url, callback) => {
        var xhr = new XMLHttpRequest();
        xhr.onload = function() {
          var reader = new FileReader();
          reader.onloadend = function() {
            callback(reader.result);
          }
          reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
      }

      
    toDataURL(url, function(dataUrl) {
      newUrl = dataUrl

    pdfMake.vfs = pdfMake.vfs;

const header = [
     
      {text: `${getTextNomeclatura(DataGuia.nomeclatura)}${DataGuia.user.name} `, fontSize: 20},
      { columns: [     
        {         
        text: `${DataGuia.street}, ${DataGuia.number} \n `+
                `${DataGuia.neighborhood} - CEP ${DataGuia.zip_code}\n`+
                `${DataGuia.city}/${DataGuia.state} - Brasil`,
        },
        {
            image: `${newUrl? newUrl : '' }`,
            width: 180,        
        }]
      },

      {text: `Relatório de ${NameReport}${idPaciente !== undefined ? ' de '+ SelectedPatient : ''}`, fontSize: 16 , alignment: 'center',margin: [0, 40,0,20] ,  bold: true}

]
///////////////////////// Tratamento de conteudos conforme NameReport ////////////////////////////////

const details = [
  listaImgBase64.map((item,index) => {
    return(
      {
        image: `${ item.link }`, width: 450, alignment: 'center' , margin: [0, 20, 0, 20]
      }
    ) 
  })
];

const detalhes = [

  {
      text: idPaciente !== undefined ? `Os Marcos de Desenvolvimento Motor nos leva simplesmente a comparar o desempenho no que diz respeito ao desenvolvimento esperado para a idade.  
      As janelas são recomendadas para comparações descritivas entre as populações, para sinalizar a necessidade de triagem apropriada quando crianças individuais parecem estar atrasadas em alcançar os marcos, e ligar atenção à importância do desenvolvimento geral em saúde infantil.`: '' , fontSize: 12,alignment: 'justify'
  },

]
    
    function Rodape(currentPage, pageCount){
        return [
          
            {text: `Documento gerado por Clinic Plus - Sistema para gestão de clínicas e consultórios. \n ${newDate}`, fontSize: 12,margin: [30, 0, 50,0], alignment: 'justify'},
            {text: currentPage + ' / ' + pageCount,alignment: 'right',fontSize: 9,margin: [0, 10, 40, 0]},
                   
        ]
    }

        const docDefinitios = {
            pageSize: 'A4',
            pageMargins: [50, 50, 50, 40],
            content: [header, details, detalhes],
            footer: Rodape,
        }
        
        pdfMake.createPdf(docDefinitios).download()
    
})
}

export default PdfByDesenvolvimentoMotor;