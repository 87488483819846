import pdfMake from 'pdfmake/build/pdfmake'
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment'


const date = new Date()
const newDate = moment(date).format('DD/MM/YYYY')

const  NameReport = localStorage.getItem("title_form");
const SelectedPatient = localStorage.getItem("SelectedPatient")


function getTextNomeclatura(nomeclatura){
  let textNomeclatura = {
    'Dr': "Dr.",
    'Dra': "Dra.",
    '-': ''
  }
  return textNomeclatura[nomeclatura] ?? ''
}


function PdfByCraniometria( DataGuia , listaImgBase64 , idPaciente ){

    const url = DataGuia.avatar
    let newUrl = '' 
    const toDataURL = (url, callback) => {
        var xhr = new XMLHttpRequest();
        xhr.onload = function() {
          var reader = new FileReader();
          reader.onloadend = function() {
            callback(reader.result);
          }
          reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
      }

      
    toDataURL(url, function(dataUrl) {
      newUrl = dataUrl


    pdfMake.vfs = pdfMake.vfs;

const header = [
     
      {text: `${getTextNomeclatura(DataGuia.nomeclatura)}${DataGuia.user.name} `, fontSize: 20},
      { columns: [     
        {         
        text: `${DataGuia.street}, ${DataGuia.number} \n `+
                `${DataGuia.neighborhood} - CEP ${DataGuia.zip_code}\n`+
                `${DataGuia.city}/${DataGuia.state} - Brasil`,
        },
        {
            image: `${newUrl? newUrl : '' }`,
            width: 180,        
        }]
      },

      {text: `Relatório de ${NameReport}${idPaciente !== undefined ? ' de '+ SelectedPatient : ''}`, fontSize: 16 , alignment: 'center',margin: [0, 40,0,20] ,  bold: true}

]
///////////////////////// Tratamento de conteudos conforme NameReport ////////////////////////////////
const details = [
    
        
        {
          text: idPaciente !== undefined ? `${listaImgBase64[0].title}`: '' , fontSize: 15,alignment: 'justify', margin: [20, 20, 0, 20] , bold: true
        },

        {
          text: idPaciente !== undefined ? `Essa mensuração compara o lado direito ao lado esquerdo do crânio. São realizadas medidas nas diagonais anterior esquerda para posterior direita e anterior direita para a posterior esquerda no plano de medição e feita a comparação entre as mesmas. `: '' , fontSize: 12,alignment: 'justify'
        },

        {
          image: `${ listaImgBase64[0].link }`, width: 350, alignment: 'center' , margin: [0, 20, 0, 25]
        },

        {
          text: idPaciente !== undefined ? `Índices: 0 à 10 mm (leve), 10 à 20 mm (moderado), 20 à 30mm (grave)`: '' , fontSize: 10 , alignment: 'center'
        },

/////////////////

        {
          text: idPaciente !== undefined ? `${listaImgBase64[1].title}`: '' ,  fontSize: 15,alignment: 'justify', margin: [20, 20, 0, 20] , bold: true
        },

        {
          text: idPaciente !== undefined ? `O Índice Cranial é a mensuração mais utilizada para avaliar a deformidade craniana na escafocefalia. Este índice é classicamente referido como a proporção da largura máxima para o comprimento do crânio, multiplicado por 100. `: '' , fontSize: 12,alignment: 'justify'
        },

        {
          image: `${ listaImgBase64[1].link }`, width: 350, alignment: 'center' , margin: [0, 20, 0, 25]
        },

        {
          text: idPaciente !== undefined ? `Regra de cáculo: Largura dividido pelo comprimento x 100`: '' , fontSize: 10 , alignment: 'center'
        },

        {
          text: idPaciente !== undefined ? `Normal (entre 75% à 90%) Braquicefalia (90% à 100%) Dolicocefalia (60% à 75%)`: '' , fontSize: 10 , alignment: 'center'
        },

////////////////

        {
          text: idPaciente !== undefined ? `${listaImgBase64[2].title}`: '' ,  fontSize: 15,alignment: 'justify', margin: [20, 20, 0, 20] , bold: true
        },

        {
          text: idPaciente !== undefined ? `O Índice de Assimetria da abóbada craniana (CVA), também conhecida como diferença diagonal oblíqua ou diferença transcraniana, é obtida subtraindo a diagonal cranial menor do diagonal cranial maior. O mesmo é encontrado através do índice de valor absoluto da diferença nas diagonais cranianas, dividido pela diagonal menor e multiplicado por 100.`: '' , fontSize: 12,alignment: 'justify'
        },

        {
          image: `${ listaImgBase64[2].link }`, width: 350, alignment: 'center' , margin: [0, 20, 0, 25]
        },

        {
          text: idPaciente !== undefined ? `Índices de referência: Normal: < 3,5% ; Leve: 3,5 – 6, 25%; Moderado: 6,25 - 8,75%; Severo: 8,75 - 11%, Muito Severo > 11%.`: '' , fontSize: 10 , alignment: 'center'
        },

    ];

// const detalhes = [

//   {
//       text: idPaciente !== undefined ? `Craniometria`: '' , fontSize: 12,alignment: 'justify'
//   },

// ]

    

      
    function Rodape(currentPage, pageCount){
        return [
          
            {text: `Documento gerado por Clinic Plus - Sistema para gestão de clínicas e consultórios. \n ${newDate}`, fontSize: 12,margin: [30, 0, 50,0], alignment: 'justify'},
            {text: currentPage + ' / ' + pageCount,alignment: 'right',fontSize: 9,margin: [0, 10, 40, 0]},
                   
        ]
    }

        const docDefinitios = {
            pageSize: 'A4',
            pageMargins: [50, 50, 50, 40],
            content: [header, details],
            footer: Rodape,
        }
        
        pdfMake.createPdf(docDefinitios).download()
    
})
}

export default PdfByCraniometria;