import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import defaultIMG from "../../../Assets/Images/default-user-image.png"
import moment from 'moment'

const date = new Date()
const newDate = moment(date).format('DD/MM/YYYY')

function getTextNomeclatura(nomeclatura){
  let textNomeclatura = {
    'Dr': "Dr.",
    'Dra': "Dra.",
    '-': ''
  }
  return textNomeclatura[nomeclatura] ?? ''
}

function GeraPdf( DataGuia , props , descGuia ){
    console.log('GeraPdf')
    const url = DataGuia.avatar === null ? defaultIMG : DataGuia.avatar
    console.log(DataGuia.avatar)
    let newUrl = '' 
    
    const toDataURL = (url, callback) => {
      var xhr = new XMLHttpRequest();
      console.log('XmlHttpRequest')
      xhr.onload = function() {
        var reader = new FileReader();
        reader.onloadend = function() {
          callback(reader.result);
        }
        console.log(xhr.response)
        reader.readAsDataURL(xhr.response);
      };
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    }
      
    toDataURL(url, function(dataUrl){ 
      newUrl = dataUrl

    pdfMake.vfs = pdfMake.vfs;

const details = [
    
        {text: `${getTextNomeclatura(DataGuia.nomeclatura)}${DataGuia.user.name} `, fontSize: 20},
        { columns: [     
            {         
            text: `${DataGuia.street}, ${DataGuia.number} \n `+
                    `${DataGuia.neighborhood} - CEP ${DataGuia.zip_code}\n`+
                    `${DataGuia.city}/${DataGuia.state} - Brasil`,
            },
            {
                image: `${!newUrl? '': newUrl}`,
                width: 180,        
            }]
        },

        {text: `${props.nomePac} `, fontSize: 16, alignment: 'center',margin: [0, 65,0,0]},
        {text: `Registro ${getTextNomeclatura(DataGuia.nomeclatura)}${DataGuia.user.name} em ${newDate}`, fontSize: 12, alignment: 'center',margin: [0, 8,0,0]},

        {text: `${descGuia}`, fontSize: 12,margin: [0, 40,0,0],alignment: 'justify'},

        { columns: [     
          {         
          },
          {        
          },
          {alignment: 'center',margin: [0, 150,0,0],
                  table: {
                    widths: ['*'],
                    body: [[" "], [`${getTextNomeclatura(DataGuia.nomeclatura)}${DataGuia.user.name}\n ${DataGuia.speciality.name}\n`]]
            },
            layout: {
                hLineWidth: function(i, node) {
                    return (i === 0 || i === node.table.body.length) ? 0 : 2;
                },
                vLineWidth: function(i, node) {
                    return 0;
                },
                
            },
  
          }]
      },
        
    ];

      
    function Rodape(currentPage, pageCount){
        return [
          
            {text: `Documento gerado por Clinic Plus - Sistema para gestão de clínicas e consultórios.`, fontSize: 12,margin: [30,0,0,0], alignment: 'center'},
            {text: currentPage + ' / ' + pageCount,alignment: 'right',fontSize: 9,margin: [0, 10, 40, 0]},
                   
        ]
    }

        const docDefinitios = {
            pageSize: 'A4',
            pageMargins: [50, 50, 50, 40],
            content: [details],
            footer: Rodape,
        }
        
        pdfMake.createPdf(docDefinitios).download()
    
})
}

export default GeraPdf;